import changePassword from './changePassword';
import updateProfile from './updateProfile';
import newPassword from './newPassword';
import verifyAccount from './verifyAccount';

export default {
  changePassword,
  updateProfile,
  newPassword,
  verifyAccount,
};
