import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import upsertPaymentAction from '../redux/actions/payments/upsertPayment';
import Icon from '../components/Icon';

const Payment = ({ upsertPaymentAction: upsertPayment, upsert, location }) => {
  const history = useHistory();
  const [pin, setPin] = useState('');
  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [token, setToken] = useState('');
  const [proceedData, setProceedData] = useState('');

  useEffect(() => {
    const urlToken = location.search.slice(
      location.search.indexOf('?token=') + 7,
      location.search.length
    );
    setToken(urlToken);
    if (!localStorage.getItem('PAY_PROCEED_DATA')) {
      return history.push(`/update-payment/card?token=${urlToken}`);
    }
    const payData = localStorage.getItem('PAY_PROCEED_DATA');
    setProceedData(JSON.parse(payData));

    if (upsert.status === 'proceed_success') {
      setSubmitting(false);
      localStorage.removeItem('PAY_PROCEED_DATA');
      localStorage.setItem('PIN_PROCEED_DATA', upsert.data.flw_ref);
      setErrors([]);
      return history.push(
        `/update-payment/card/proceed/verify-otp?token=${urlToken}`
      );
    }

    if (upsert.status === 'proceed_error') {
      setSubmitting(false);
      return setErrors([upsert.error.message]);
    }

    return undefined;
  }, [upsert]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      billCycle: proceedData.billCycle,
      method: proceedData.method,
      card_number: proceedData.card_number,
      expiry_month: proceedData.expiry_month,
      expiry_year: proceedData.expiry_year,
      cvv: proceedData.cvv,
      phone_number: proceedData.phone_number,
    };

    if (!pin) {
      return setErrors(['Please Enter your pin!']);
    }

    const pinData = {
      mode: 'pin',
      pin,
    };

    data.authorization = pinData;
    setErrors([]);
    setSubmitting(true);
    return upsertPayment('proceed', data, token);
  };

  return (
    <>
      <div className="view-container">
        <div className="wrapper-content px-4 bg-dark w-100 m-auto">
          <div className="form-content d-block bg-secondary px-5 py-4">
            <h4 className="text-white text-center xlarge-text">
              Proceed Payment
            </h4>
            <p className="text-white text-center" style={{ fontSize: 15 }}>
              Please provide your pin
            </p>

            <div className="centered-icon">
              <div className="bg-dark p-2" style={{ borderRadius: 5 }}>
                <Icon
                  name="creditCard"
                  height={30}
                  width={30}
                  color="#ffffff"
                />
              </div>
            </div>
            <form action="" className="mt-3" autoComplete="off">
              {errors.map((error, index) => (
                <div
                  className="alert alert-danger alert-dismissible"
                  role="alert"
                  key={index}
                >
                  {error}
                  <button
                    type="button"
                    className="close"
                    onClick={() => setErrors([])}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              ))}
              <div className="form-row">
                <div className="form-group col-12 mb-1">
                  <label htmlFor="pin">Pin</label>
                  <input
                    id="pin"
                    required
                    className={`form-control dark-input bg-dark text-white ${
                      submitting ? 'cursor-not-allowed' : ''
                    }`}
                    placeholder="Your Pin"
                    inputMode="numeric"
                    autoComplete="off"
                    pattern="[0-9]*"
                    value={pin}
                    onChange={(e) => setPin(e.target.value)}
                    style={{ fontSize: 14 }}
                    minLength="3"
                    disabled={!!submitting}
                    type="password"
                  />
                </div>
              </div>
              <div className="form-group mt-3">
                {submitting ? (
                  <button
                    type="submit"
                    className="btn btn-secondary py-1 text-white font-weight-bold mt-3 form-control"
                    disabled
                  >
                    Proceeding
                    <span
                      className="spinner-border spinner-border ml-2"
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        fontSize: '0.7rem',
                      }}
                      role="status"
                      aria-hidden="true"
                    />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="form-control btn btn-primary text-dark py-1 font-weight-bold mt-3"
                    onClick={handleSubmit}
                  >
                    Proceed
                  </button>
                )}
                <Link to={`/update-payment/card?token=${token}`}>
                  <button
                    type="button"
                    className="form-control btn bg-light-secondary text-white py-1 font-weight-bold mt-3"
                  >
                    Back
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

Payment.propTypes = {
  upsert: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  upsertPaymentAction: PropTypes.func.isRequired,
};

const mapStateProps = ({ upsert }) => ({ upsert });

export default connect(mapStateProps, {
  upsertPaymentAction,
})(Payment);
