import { config, SubscriptionService } from '..';
import {
  INITIATE_PAYMENT_SUCCESS,
  INITIATE_PAYMENT_FAILED,
} from '../../actionTypes';

export default (token) => async (dispatch) => {
  try {
    const response = await SubscriptionService.post(
      '/payment/manual',
      {},
      config(token)
    );

    const {
      data: { message, data },
    } = response;
    dispatch({
      type: INITIATE_PAYMENT_SUCCESS,
      message,
      data,
    });
  } catch (err) {
    let error = {};
    if (err.response) {
      const {
        data: { status, message },
      } = err.response;
      error = { status, message };
    } else {
      error = {
        status: 500,
        message: err.message,
      };
    }
    dispatch({ type: INITIATE_PAYMENT_FAILED, error });
  }
};
