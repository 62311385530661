import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import validator from '../helpers/validator';
import changePasswordAction from '../redux/actions/user/changePassword';

const EditPassword = ({
  changePasswordAction: changePassword,
  changePasswordData,
  location,
}) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [changePwdSuccess, setChangePwdSuccess] = useState(false);
  useEffect(() => {
    setSubmitting(false);
    if (changePasswordData.status === 'error') {
      return setErrors([changePasswordData.error.message]);
    }

    if (changePasswordData.status === 'success') {
      setChangePwdSuccess(true);
      setOldPassword('');
      setNewPassword('');
    }
    return undefined;
  }, [changePasswordData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { oldPassword, newPassword };
    const validationErrors = await validator(data);
    if (validationErrors.length > 0) return setErrors(validationErrors);
    setErrors([]);
    setSubmitting(true);
    const urlToken = location.search.slice(
      location.search.indexOf('?token=') + 7,
      location.search.length
    );
    return changePassword(data, urlToken);
  };

  return (
    <>
      <div className="view-container">
        <div className="wrapper-content px-4 mt-2 bg-dark w-100 m-auto">
          <div className="form-content d-block bg-secondary px-5 pt-4 pb-4">
            <h4 className="text-white text-center xlarge-text">
              Change Password
            </h4>
            <form className="mt-4">
              {errors.map((error, index) => (
                <div
                  className="alert alert-danger px-2"
                  role="alert"
                  key={index}
                >
                  {error}
                  <button
                    type="button"
                    className="close"
                    onClick={() => setErrors([])}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              ))}
              {changePwdSuccess ? (
                <div
                  className="alert alert-success text-center medium-text py-1 alert-dismissible"
                  role="alert"
                >
                  Password changed successfully!
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={() => setChangePwdSuccess(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              ) : null}
              <div className="form-group">
                <label htmlFor="currentPwd">Current Password</label>
                <input
                  type="password"
                  id="currentPwd"
                  className={`form-control dark-input bg-dark text-white ${
                    submitting ? 'cursor-not-allowed' : ''
                  }`}
                  required
                  disabled={!!submitting}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="newPwd">New Password</label>
                <input
                  type="password"
                  id="newPwd"
                  className={`form-control dark-input bg-dark text-white ${
                    submitting ? 'cursor-not-allowed' : ''
                  }`}
                  required
                  value={newPassword}
                  disabled={!!submitting}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="form-group mt-4">
                {submitting ? (
                  <button
                    type="submit"
                    className="btn btn-secondary py-1 text-white form-control login-button font-weight-bold"
                    disabled
                  >
                    Updating
                    <span
                      className="spinner-border spinner-border ml-2"
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        fontSize: '0.7rem',
                      }}
                      role="status"
                      aria-hidden="true"
                    />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="form-control btn btn-primary text-dark py-1 font-weight-bold"
                    onClick={handleSubmit}
                  >
                    Update
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

EditPassword.propTypes = {
  changePasswordAction: PropTypes.func.isRequired,
  changePasswordData: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ changePassword }) => ({
  changePasswordData: changePassword,
});

export default connect(mapStateToProps, { changePasswordAction })(EditPassword);
