import axios from 'axios';

export default axios.create({ baseURL: 'https://api.ingoma.app' });

export const SubscriptionService = axios.create({
  baseURL: 'https://subscription.ingoma.app',
});

export const config = (token) => ({
  headers: {
    ContentType: 'application/json',
    Authorization: `Bearer ${token}`,
  },
});
