import getPayment from './fetchPayment';
import upsert from './upsertPayment';
import initiatePayment from './initiatePayment';
import verifyOtp from './verifyOtp';

export default {
  getPayment,
  upsert,
  initiatePayment,
  verifyOtp,
};
