export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';
export const FETCH_PAYMENT_SUCCESS = 'FETCH_PAYMENT_SUCCESS';
export const FETCH_PAYMENT_FAILED = 'FETCH_PAYMENT_FAILED';
export const UPSERT_PAYMENT_SUCCESS = 'UPSERT_PAYMENT_SUCCESS';
export const UPSERT_PAYMENT_FAILED = 'UPSERT_PAYMENT_FAILED';
export const PROCEED_PAYMENT_SUCCESS = 'PROCEED_PAYMENT_SUCCESS';
export const PROCEED_PAYMENT_FAILED = 'PROCEED_PAYMENT_FAILED';
export const CLEAR_PAYMENT = 'CLEAR_PAYMENT';
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS';
export const NEW_PASSWORD_FAILED = 'NEW_PASSWORD_FAILED';
export const VERIFY_ACCOUNT_SUCCESS = 'VERIFY_ACCOUNT_SUCCESS';
export const VERIFY_ACCOUNT_FAILED = 'VERIFY_ACCOUNT_FAILED';
export const INITIATE_PAYMENT_SUCCESS = 'INITIATE_PAYMENT_SUCCESS';
export const INITIATE_PAYMENT_FAILED = 'INITIATE_PAYMENT_FAILED';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILED = 'VERIFY_OTP_FAILED';
export const CLEAR_VERIFY_OTP = 'CLEAR_VERIFY_OTP';
