import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Error from '../components/Error';
import Loader from '../components/Loader';
import UpdateProfile from '../components/UpdateProfile';
import fetchProfile from '../redux/actions/user/fetchProfile';

const EditProfile = ({ location }) => {
  const [status, setStatus] = useState('fetching');
  const [profile, setProfile] = useState({});
  const [token, setToken] = useState(null);

  useEffect(() => {
    const urlToken = location.search.slice(
      location.search.indexOf('?token=') + 7,
      location.search.length
    );
    setToken(urlToken);

    const initiate = async () => {
      try {
        const profileData = await fetchProfile(urlToken);
        setProfile(profileData);
        setStatus('success');
      } catch (error) {
        setStatus('error');
      }
    };
    initiate();

    return undefined;
  }, []);

  const refetch = () => {
    setStatus('fetching');
    const initiate = async () => {
      try {
        const profileData = await fetchProfile(token);
        setProfile(profileData);
        setStatus('success');
      } catch (error) {
        setStatus('error');
      }
    };
    initiate();
  };

  const DisplayData = () => {
    let data;

    if (status === 'fetching') {
      data = <Loader />;
    }
    if (status === 'error') {
      data = (
        <Error
          title="Error!"
          description="Something went wrong, Please try again in a while."
        />
      );
    }
    if (status === 'success') {
      data = (
        <>
          <UpdateProfile
            profile={profile}
            urlToken={token}
            refetch={refetch}
          />
        </>
      );
    }
    return data;
  };

  return (
    <>
      <div className="view-container">
        <div className="wrapper-content px-4 mt-2 bg-dark w-100 m-auto">
          <div className="form-content d-block bg-secondary px-5 pt-4 pb-4">
            <h4 className="text-white text-center xlarge-text">Edit Profile</h4>
            <DisplayData />
          </div>
        </div>
      </div>
    </>
  );
};

EditProfile.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default EditProfile;
