import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import validator from '../helpers/validator';
import newPasswordAction from '../redux/actions/user/newPassword';
import Logo from '../components/Logo';

const NewPassword = ({
  newPasswordAction: newPassword,
  newPassword: setNewPassword,
  location,
}) => {
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [resetSuccess, SetResetSuccess] = useState(false);
  const [showLink, setShowLink] = useState(false);
  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform)
    ) {
      setShowLink(true);
    } else {
      setShowLink(false);
    }
    setSubmitting(false);
    if (setNewPassword.status === 'error')
      return setErrors([setNewPassword.error.message]);
    if (setNewPassword.status === 'success') {
      SetResetSuccess(true);
      return history.replace(`${location.pathname}?reset=success`);
    }
    return undefined;
  }, [setNewPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      password,
    };
    const urlToken = location.search.slice(
      location.search.indexOf('?token=') + 7,
      location.search.length
    );
    const validationErrors = await validator({ ...data });
    if (validationErrors.length > 0) return setErrors(validationErrors);
    setErrors([]);
    setSubmitting(true);
    return newPassword(data, urlToken);
  };

  return (
    <>
      <Logo />
      <div className="wrapper-content px-4 mt-2 bg-dark">
        <div className="form-content d-block bg-secondary px-5 pt-4 pb-4">
          <h4 className="text-white text-center xlarge-text">Reset Password</h4>
          {errors.map((error, index) => (
            <div
              className="alert alert-danger alert-dismissible mt-3"
              role="alert"
              key={index}
            >
              {error}
              <button
                type="button"
                className="close"
                onClick={() => setErrors([])}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          ))}
          {resetSuccess ? (
            <>
              <div className="alert alert-warning mt-3" role="alert">
                Your Password has been successfully reset, Proceed to login with
                your new password.
              </div>
              {showLink ? (
                <p className="text-center my-4">
                  <a href="ingoma://" className="text-primary">
                    Open App
                  </a>
                </p>
              ) : null}
            </>
          ) : (
            <form action="" className="mt-4">
              <div className="form-group">
                <input
                  type="password"
                  className={`form-control dark-input bg-dark text-white ${
                    submitting ? 'cursor-not-allowed' : ''
                  }`}
                  placeholder="New Password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  disabled={!!submitting}
                  required
                />
              </div>
              <div className="form-group text-center">
                {submitting ? (
                  <button
                    type="submit"
                    className="btn btn-secondary px-5 py-2 mb-2 mt-3 form-control"
                    disabled
                  >
                    Updating
                    <span
                      className="spinner-border spinner-border ml-2"
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        fontSize: '0.7rem',
                      }}
                      role="status"
                      aria-hidden="true"
                    />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary px-5 py-2 mb-2 mt-3 form-control"
                    style={{ color: '#000' }}
                    onClick={handleSubmit}
                  >
                    Update
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

NewPassword.propTypes = {
  newPasswordAction: PropTypes.func.isRequired,
  newPassword: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ newPassword }) => ({ newPassword });

export default connect(mapStateToProps, { newPasswordAction })(NewPassword);
