import { config, SubscriptionService } from '..';
import { VERIFY_OTP_SUCCESS, VERIFY_OTP_FAILED, CLEAR_VERIFY_OTP } from '../../actionTypes';

export default (data, token) => async (dispatch) => {
  try {
    const response = await SubscriptionService.post(
      '/payment/verify-otp',
      data,
      config(token)
    );

    const {
      data: { message },
    } = response;
    dispatch({
      type: VERIFY_OTP_SUCCESS,
      message,
    });
  } catch (err) {
    let error = {};
    if (err.response) {
      const {
        data: { status, message },
      } = err.response;
      error = { status, message };
    } else {
      error = {
        status: 500,
        message: err.message,
      };
    }
    dispatch({ type: VERIFY_OTP_FAILED, error });
  }
};

export const clearStatus = () => (dispatch) => dispatch({ type: CLEAR_VERIFY_OTP });
