import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import updateProfileAction from '../redux/actions/user/updateProfile';

const UpdateProfile = ({
  profile,
  updateProfile: updatedProfile,
  updateProfileAction: updateAction,
  urlToken,
  refetch,
}) => {
  const [status, setStatus] = useState('');
  const [errors, setErrors] = useState([]);
  const [firstname, setFirstname] = useState('');
  const [username, setUsername] = useState('');
  const [lastname, setLastname] = useState('');
  const [triggerRefetch, setTriggerRefetch] = useState(false);

  useEffect(() => {
    setFirstname(profile.firstname);
    setUsername(profile.username);
    setLastname(profile.lastname);

    if (updatedProfile.status === 'success') {
      setStatus('success');
      setErrors([]);
      if (triggerRefetch) refetch();
      setTriggerRefetch(false);
    }

    if (updatedProfile.status === 'error') {
      setStatus('');
      return setErrors([updatedProfile.error.message]);
    }

    return undefined;
  }, [updatedProfile]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!firstname || !username || !lastname) {
      return setErrors(['All fields are required']);
    }
    let payload;
    if (
      firstname !== profile.firstname ||
      username !== profile.username ||
      lastname !== profile.lastname
    ) {
      payload = { firstname, username, lastname };
    }

    if (payload) {
      updateAction(payload, urlToken);
      setTriggerRefetch(true);
    } else {
      return setErrors(['Please change any thing, to update!']);
    }

    setErrors([]);
    return setStatus('submitting');
  };

  return (
    <>
      <form className="mt-4">
        {status === 'success' ? (
          <div className="alert alert-warning text-center" role="alert">
            Successfully updated your profile
          </div>
        ) : (
          <div />
        )}
        {errors.map((error, index) => (
          <div
            className="alert alert-danger text-center alert-dismissible"
            role="alert"
            key={index}
          >
            {error}
            <button
              type="button"
              className="close"
              onClick={() => setErrors([])}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        ))}
        <div className="form-group">
          <label htmlFor="firstname">Firstname</label>
          <input
            type="text"
            id="firstname"
            className={`form-control dark-input bg-dark text-white ${
              status === 'submitting' ? 'cursor-not-allowed' : ''
            }`}
            value={firstname}
            disabled={status === 'submitting'}
            required
            onChange={(e) => setFirstname(e.target.value)}
          />
        </div>
        <div className="form-group mt-3">
          <label htmlFor="lastname">Lastname</label>
          <input
            type="text"
            id="lastname"
            className={`form-control dark-input bg-dark text-white ${
              status === 'submitting' ? 'cursor-not-allowed' : ''
            }`}
            disabled={status === 'submitting'}
            value={lastname}
            required
            onChange={(e) => setLastname(e.target.value)}
          />
        </div>
        <div className="form-group mt-3">
          <label htmlFor="lastname">Username</label>
          <input
            type="text"
            id="lastname"
            className={`form-control dark-input bg-dark text-white ${
              status === 'submitting' ? 'cursor-not-allowed' : ''
            }`}
            disabled={status === 'submitting'}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        {status === 'submitting' ? (
          <button
            type="submit"
            className="btn btn-secondary py-1 text-white form-control login-button font-weight-bold"
            disabled
          >
            Updating
            <span
              className="spinner-border spinner-border ml-2"
              style={{
                width: '1.5rem',
                height: '1.5rem',
                fontSize: '0.7rem',
              }}
              role="status"
              aria-hidden="true"
            />
          </button>
        ) : (
          <button
            type="submit"
            className="form-control btn btn-primary text-dark py-1 font-weight-bold"
            onClick={handleSubmit}
          >
            Update
          </button>
        )}
      </form>
    </>
  );
};

UpdateProfile.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
  updateProfile: PropTypes.objectOf(PropTypes.any).isRequired,
  updateProfileAction: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  urlToken: PropTypes.string.isRequired,
};

const mapStateProps = ({ updateProfile }) => ({ updateProfile });

export default connect(mapStateProps, {
  updateProfileAction,
})(UpdateProfile);
