import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import Loader from '../components/Loader';
import upsertPaymentAction from '../redux/actions/payments/upsertPayment';
import Icon from '../components/Icon';

const Payment = ({ upsertPaymentAction: upsertPayment, upsert, location }) => {
  const history = useHistory();
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [token, setToken] = useState('');
  const [proceedData, setProceedData] = useState('');
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    const urlToken = location.search.slice(
      location.search.indexOf('?token=') + 7,
      location.search.length
    );
    setToken(urlToken);
    if (!localStorage.getItem('PAY_PROCEED_DATA')) {
      return history.push(`/update-payment/card?token=${urlToken}`);
    }
    const payData = localStorage.getItem('PAY_PROCEED_DATA');
    setProceedData(JSON.parse(payData));

    if (upsert.status === 'proceed_success') {
      setSubmitting(false);
      setRedirecting(true);
      localStorage.removeItem('PAY_PROCEED_DATA');
      setErrors([]);
      window.location.assign(upsert.data.payment.authorization.redirect);
    }

    if (upsert.status === 'proceed_error') {
      setSubmitting(false);
      return setErrors([upsert.error.message]);
    }

    return undefined;
  }, [upsert]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      billCycle: proceedData.billCycle,
      method: proceedData.method,
      card_number: proceedData.card_number,
      expiry_month: proceedData.expiry_month,
      expiry_year: proceedData.expiry_year,
      cvv: proceedData.cvv,
      phone_number: proceedData.phone_number,
    };

    if (!city || !address || !state || !country || !zipcode) {
      return setErrors(['All fields are required']);
    }

    const avsData = {
      mode: 'avs_noauth',
      city,
      address,
      state,
      country,
      zipcode,
    };

    data.authorization = avsData;
    setErrors([]);
    setSubmitting(true);
    return upsertPayment('proceed', data, token);
  };

  return (
    <>
      <div className="view-container">
        <div className="wrapper-content px-4 bg-dark w-100 m-auto">
          <div className="form-content d-block bg-secondary px-5 py-4">
            <h4 className="text-white text-center xlarge-text">
              Proceed Payment
            </h4>
            <p className="text-white text-center" style={{ fontSize: 15 }}>
              Fill the Following Fields
            </p>

            <div className="centered-icon">
              <div className="bg-dark p-2" style={{ borderRadius: 5 }}>
                <Icon
                  name="creditCard"
                  height={30}
                  width={30}
                  color="#ffffff"
                />
              </div>
            </div>
            {redirecting ? (
              <>
                <Loader />
                <p className="mt-0 text-white text-center">Finishing Up</p>
              </>
            ) : (
              <form action="" className="mt-3">
                {errors.map((error, index) => (
                  <div
                    className="alert alert-danger alert-dismissible"
                    role="alert"
                    key={index}
                  >
                    {error}
                    <button
                      type="button"
                      className="close"
                      onClick={() => setErrors([])}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ))}
                <div className="form-row">
                  <div className="form-group col-6 mb-1">
                    <label htmlFor="city">City</label>
                    <input
                      id="city"
                      required
                      className={`form-control dark-input bg-dark text-white ${
                        submitting ? 'cursor-not-allowed' : ''
                      }`}
                      placeholder="City"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      style={{ fontSize: 14 }}
                      minLength="2"
                      disabled={!!submitting}
                    />
                  </div>
                  <div className="form-group col-6 mb-1">
                    <label htmlFor="address">Address</label>
                    <input
                      id="address"
                      required
                      className={`form-control dark-input bg-dark text-white ${
                        submitting ? 'cursor-not-allowed' : ''
                      }`}
                      placeholder="Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      style={{ fontSize: 14 }}
                      minLength="2"
                      disabled={!!submitting}
                    />
                  </div>
                  <div className="form-group col-6 mb-1">
                    <label htmlFor="state">State</label>
                    <input
                      id="state"
                      required
                      className={`form-control dark-input bg-dark text-white ${
                        submitting ? 'cursor-not-allowed' : ''
                      }`}
                      placeholder="State"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      style={{ fontSize: 14 }}
                      minLength="2"
                      disabled={!!submitting}
                    />
                  </div>
                  <div className="form-group col-6 mb-1">
                    <label htmlFor="country">Country</label>
                    <input
                      id="country"
                      required
                      className={`form-control dark-input bg-dark text-white ${
                        submitting ? 'cursor-not-allowed' : ''
                      }`}
                      placeholder="Country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      style={{ fontSize: 14 }}
                      minLength="2"
                      disabled={!!submitting}
                    />
                  </div>
                  <div className="form-group col-6 mb-1">
                    <label htmlFor="zipcode">Zipcode</label>
                    <input
                      id="zipcode"
                      required
                      className={`form-control dark-input bg-dark text-white ${
                        submitting ? 'cursor-not-allowed' : ''
                      }`}
                      placeholder="Zipcode"
                      value={zipcode}
                      onChange={(e) => setZipcode(e.target.value)}
                      style={{ fontSize: 14 }}
                      minLength="2"
                      disabled={!!submitting}
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  {submitting ? (
                    <button
                      type="submit"
                      className="btn btn-secondary py-1 text-white font-weight-bold mt-3 form-control"
                      disabled
                    >
                      Proceeding
                      <span
                        className="spinner-border spinner-border ml-2"
                        style={{
                          width: '1.5rem',
                          height: '1.5rem',
                          fontSize: '0.7rem',
                        }}
                        role="status"
                        aria-hidden="true"
                      />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="form-control btn btn-primary text-dark py-1 font-weight-bold mt-3"
                      onClick={handleSubmit}
                    >
                      Proceed
                    </button>
                  )}
                  <Link to={`/update-payment/card?token=${token}`}>
                    <button
                      type="button"
                      className="form-control btn bg-light-secondary text-white py-1 font-weight-bold mt-3"
                    >
                      Back
                    </button>
                  </Link>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

Payment.propTypes = {
  upsert: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  upsertPaymentAction: PropTypes.func.isRequired,
};

const mapStateProps = ({ upsert }) => ({ upsert });

export default connect(mapStateProps, {
  upsertPaymentAction,
})(Payment);
