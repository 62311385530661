import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import Icon from '../components/Icon';
import Loader from '../components/Loader';
import validator from '../helpers/validator';
import upsertPaymentAction from '../redux/actions/payments/upsertPayment';

const Payment = ({ upsertPaymentAction: upsertPayment, upsert, location }) => {
  const history = useHistory();
  const [momoNumber, setMomoNumber] = useState('');
  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    const urlToken = location.search.slice(
      location.search.indexOf('?token=') + 7,
      location.search.length
    );
    setToken(urlToken);

    if (upsert.status === 'success') {
      if (!upsert.data.meta) return history.push(`/subscription/details?token=${token}`);
      setSubmitting(false);
      setRedirecting(true);
      setErrors([]);
      window.location.assign(upsert.data.meta.authorization.redirect);
    }

    if (upsert.status === 'error') {
      setSubmitting(false);
      return setErrors([upsert.error.message]);
    }

    return undefined;
  }, [upsert]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      billCycle: 'monthly',
      method: 'Mobile Money',
      phone_number: momoNumber,
    };
    const validationErrors = await validator({ momoNumber });
    if (validationErrors.length > 0) return setErrors(validationErrors);
    setErrors([]);
    setSubmitting(true);
    return upsertPayment('initial', data, token);
  };

  return (
    <>
      <div className="view-container">
        <div className="wrapper-content px-4 mt-2 bg-dark w-100 m-auto">
          <div className="form-content d-block bg-secondary px-5 pt-3 pb-4">
            <h4 className="text-white text-center xlarge-text">Mobile Money</h4>
            <p className="text-white text-center" style={{ fontSize: 15 }}>
              Input Required Field
            </p>

            <div className="centered-icon">
              <div className="bg-dark p-2" style={{ borderRadius: 5 }}>
                <Icon name="phone" height={30} width={30} color="#ffffff" />
              </div>
            </div>

            {redirecting ? (
              <>
                <Loader />
                <p className="mt-0 text-white text-center">Finishing Up</p>
              </>
            ) : (
              <form action="" className="mt-3">
                {errors.map((error, index) => (
                  <div
                    className="alert alert-danger alert-dismissible"
                    role="alert"
                    key={index}
                  >
                    {error}
                    <button
                      type="button"
                      className="close"
                      onClick={() => setErrors([])}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ))}
                <div className="form-group">
                  <label htmlFor="momoNumber">Phone Number</label>
                  <input
                    id="momoNumber"
                    className={`form-control dark-input bg-dark text-white ${
                      submitting ? 'cursor-not-allowed' : ''
                    }`}
                    placeholder="078XXXXXXX"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={momoNumber}
                    onChange={(e) => setMomoNumber(e.target.value)}
                    maxLength="10"
                    minLength="10"
                    style={{ fontSize: 14 }}
                    disabled={!!submitting}
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  {submitting ? (
                    <button
                      type="submit"
                      className="btn btn-secondary py-1 text-white font-weight-bold mt-3 form-control"
                      disabled
                    >
                      Updating
                      <span
                        className="spinner-border spinner-border ml-2"
                        style={{
                          width: '1.5rem',
                          height: '1.5rem',
                          fontSize: '0.7rem',
                        }}
                        role="status"
                        aria-hidden="true"
                      />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="form-control btn btn-primary text-dark py-1 font-weight-bold mt-3"
                      onClick={handleSubmit}
                    >
                      Update
                    </button>
                  )}
                  <Link to={`/update-payment/select?token=${token}`}>
                    <button
                      type="button"
                      className="form-control btn bg-light-secondary text-white py-1 font-weight-bold mt-3"
                    >
                      Back
                    </button>
                  </Link>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

Payment.propTypes = {
  upsert: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  upsertPaymentAction: PropTypes.func.isRequired,
};

const mapStateProps = ({ upsert }) => ({ upsert });

export default connect(mapStateProps, {
  upsertPaymentAction,
})(Payment);
