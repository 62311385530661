/* eslint-disable react/prop-types */
import React from 'react';

export default {
  overview: ({ width, height, color }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 3.75H17.5V26.25H12.5V3.75Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 9.99988H27.5V26.2499H22.5V9.99988Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 16.2501H7.5V26.2501H2.5V16.2501Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  music: ({ width, height, color }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="14" stroke={color} strokeWidth="2" />
      <circle cx="15" cy="15.0001" r="4" stroke={color} strokeWidth="2" />
    </svg>
  ),
  payouts: ({ width, height, color }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 7C1.25 5.89543 2.14543 5 3.25 5H26.75C27.8546 5 28.75 5.89543 28.75 7V23C28.75 24.1046 27.8546 25 26.75 25H3.25C2.14543 25 1.25 24.1046 1.25 23V7Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 12.5H28.75"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  creditCard: ({ width, height, color }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 7C1.25 5.89543 2.14543 5 3.25 5H26.75C27.8546 5 28.75 5.89543 28.75 7V23C28.75 24.1046 27.8546 25 26.75 25H3.25C2.14543 25 1.25 24.1046 1.25 23V7Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 12.5H28.75"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  phone: ({ width, height, color }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 4.5C6.25 3.39543 7.14543 2.5 8.25 2.5H21.75C22.8546 2.5 23.75 3.39543 23.75 4.5V25.5C23.75 26.6046 22.8546 27.5 21.75 27.5H8.25C7.14543 27.5 6.25 26.6046 6.25 25.5V4.5Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="15" cy="22.5" r="1.25" fill={color} />
    </svg>
  ),
};
