import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Icon from '../components/Icon';

const Payment = ({ location }) => {
  const [token, setToken] = useState('');

  useEffect(() => {
    const urlToken = location.search.slice(
      location.search.indexOf('?token=') + 7,
      location.search.length
    );
    setToken(urlToken);
    return undefined;
  });

  return (
    <>
      <div className="view-container">
        <div className="wrapper-content px-4 mt-2 bg-dark w-100 m-auto">
          <div className="form-content d-block bg-secondary px-5 py-4">
            <h4 className="text-white text-center xlarge-text">Choose Method</h4>
            <p className="text-white text-center" style={{ fontSize: 15 }}>
              Which payment method do you want to use?
            </p>
            <div
              className="payment-icon-content mt-4 p-2"
              style={{ background: '#1B1B1B', borderRadius: 5 }}
            >
              <div
                className="bg-dark text-center p-1"
                style={{ flex: 1, alignSelf: 'flex-start', borderRadius: 5 }}
              >
                <Icon name="phone" height={30} width={30} color="#ffffff" />
              </div>
              <div className="title-content ml-3" style={{ flex: 7 }}>
                <Link
                  to={`/update-payment/mobile-money?token=${token}`}
                  className="text-white large-text m-0 p-0"
                  style={{ fontSize: 16 }}
                >
                  MTN Mobile Money
                </Link>
              </div>
            </div>
            <div
              className="payment-icon-content mt-3 p-2"
              style={{ background: '#1B1B1B', borderRadius: 5 }}
            >
              <div
                className="bg-dark text-center p-1"
                style={{ flex: 1, alignSelf: 'flex-start', borderRadius: 5 }}
              >
                <Icon name="creditCard" height={30} width={30} color="#ffffff" />
              </div>
              <div className="title-content ml-3" style={{ flex: 7 }}>
                <Link
                  to={`/update-payment/card?token=${token}`}
                  className="text-white large-text m-0 p-0"
                  style={{ fontSize: 16 }}
                >
                  Credit Card
                </Link>
              </div>
            </div>
            <Link to={`/subscription/details?token=${token}`}>
              <button
                type="button"
                className="form-control btn bg-light-secondary text-white py-1 font-weight-bold mt-3"
              >
                Back
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

Payment.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Payment;
