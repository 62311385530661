import React from 'react';
import PropTypes from 'prop-types';

const Error = ({ title, description, noPadding }) => (
  <div className={`col text-center text-white ${noPadding ? '' : 'py-5'}`}>
    <h4>{title}</h4>
    <p className="mt-1 small-text">{description}</p>
  </div>
);

Error.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  noPadding: PropTypes.bool,
};

Error.defaultProps = {
  noPadding: false,
};

export default Error;
