import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import verifyAccountAction from '../redux/actions/user/verifyAccount';
import Logo from '../components/Logo';
import Loader from '../components/Loader';
import Error from '../components/Error';

const NewPassword = ({
  verifyAccountAction: verifyAccount,
  verifyAccount: verifyAccountData,
  location,
}) => {
  const history = useHistory();
  const [status, setStatus] = useState('initial');
  const [showLink, setShowLink] = useState(false);

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform)
    ) {
      setShowLink(true);
    } else {
      setShowLink(false);
    }

    if (status === 'initial') {
      const urlToken = location.search.slice(
        location.search.indexOf('?token=') + 7,
        location.search.length
      );
      verifyAccount(urlToken);
      setStatus('verifying');
    }
    if (verifyAccountData.status === 'error') {
      const { error } = verifyAccountData;
      if (error.status === 500) setStatus('unknown_error');
      if (error.status === 400) setStatus('conflict_error');
    }

    if (verifyAccountData.status === 'success') {
      setStatus('success');
      return history.replace(`${location.pathname}?verification=success`);
    }
    return undefined;
  }, [verifyAccountData]);

  const DisplayData = ({ children }) => {
    let data;
    switch (status) {
      case 'verifying':
        data = <Loader />;
        break;
      case 'success':
        data = <>{children}</>;
        break;
      case 'conflict_error':
        data = (
          <>
            <Error
              title="Already Verified!"
              description="This account is already verified, proceed to login."
            />
            <div className="text-center">
              {showLink ? (
                <a href="ingoma://" className="text-primary">
                  Open App
                </a>
              ) : null}
            </div>
          </>
        );
        break;
      default:
        data = (
          <Error
            title="Error!"
            description="Something went wrong, Please try again in a while."
          />
        );
    }
    return data;
  };

  return (
    <>
      <Logo />
      <div className="wrapper-content px-4 mt-2 bg-dark">
        <div className="form-content d-block bg-secondary px-5 pt-4 pb-4">
          <h4 className="text-white text-center xlarge-text">Verify Account</h4>
          <>
            <DisplayData>
              <div
                className="alert alert-warning mt-5 text-center"
                role="alert"
              >
                Your Account has been successfully verified, Proceed to login.
              </div>
              {showLink ? (
                <p className="text-center my-4">
                  <a href="ingoma://" className="text-primary">
                    Open App
                  </a>
                </p>
              ) : null}
            </DisplayData>
          </>
        </div>
      </div>
    </>
  );
};

NewPassword.propTypes = {
  verifyAccountAction: PropTypes.func.isRequired,
  verifyAccount: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ verifyAccount }) => ({ verifyAccount });

export default connect(mapStateToProps, { verifyAccountAction })(NewPassword);
