import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import verifyOtpAction, { clearStatus } from '../redux/actions/payments/verifyOtp';
import Icon from '../components/Icon';

const Payment = ({
  verifyOtpAction: verifyOtp,
  verifyOtp: verify,
  clearStatus: clearOTPStatus,
  location,
}) => {
  const history = useHistory();
  const [otp, setOtp] = useState('');
  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [token, setToken] = useState('');
  const [proceedData, setProceedData] = useState('');

  useEffect(() => {
    const urlToken = location.search.slice(
      location.search.indexOf('?token=') + 7,
      location.search.length
    );
    setToken(urlToken);
    if (!localStorage.getItem('PIN_PROCEED_DATA')) {
      return history.push(`/update-payment/card?token=${urlToken}`);
    }
    const payData = localStorage.getItem('PIN_PROCEED_DATA');
    setProceedData(payData);

    if (verify.status === 'success') {
      setSubmitting(false);
      setErrors([]);
      localStorage.removeItem('PIN_PROCEED_DATA');
      clearOTPStatus();
      return history.push(`/subscription/details?token=${urlToken}`);
    }

    if (verify.status === 'error') {
      setSubmitting(false);
      return setErrors([verify.error.message]);
    }

    return undefined;
  }, [verify]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      flw_ref: proceedData,
    };

    if (!otp) {
      return setErrors(['All fields are required']);
    }

    const otpData = {
      mode: 'otp-verify',
      otp,
    };

    data.authorization = otpData;
    setErrors([]);
    setSubmitting(true);
    return verifyOtp(data, token);
  };

  return (
    <>
      <div className="view-container">
        <div className="wrapper-content px-4 bg-dark w-100 m-auto">
          <div className="form-content d-block bg-secondary px-5 py-4">
            <h4 className="text-white text-center xlarge-text">Verify OTP</h4>
            <p className="text-white text-center" style={{ fontSize: 15 }}>
              Please enter the OTP sent to your mobile number
            </p>

            <div className="centered-icon">
              <div className="bg-dark p-2" style={{ borderRadius: 5 }}>
                <Icon
                  name="creditCard"
                  height={30}
                  width={30}
                  color="#ffffff"
                />
              </div>
            </div>
            <form action="" className="mt-3" autoComplete="off">
              {errors.map((error, index) => (
                <div
                  className="alert alert-danger alert-dismissible"
                  role="alert"
                  key={index}
                >
                  {error}
                  <button
                    type="button"
                    className="close"
                    onClick={() => setErrors([])}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              ))}
              <div className="form-row">
                <div className="form-group col-12 mb-1">
                  <label htmlFor="otp">OTP</label>
                  <input
                    id="otp"
                    required
                    className={`form-control dark-input bg-dark text-white ${
                      submitting ? 'cursor-not-allowed' : ''
                    }`}
                    placeholder="Your OTP"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={otp}
                    autoComplete="off"
                    onChange={(e) => setOtp(e.target.value)}
                    style={{ fontSize: 14 }}
                    minLength="3"
                    disabled={!!submitting}
                  />
                </div>
              </div>
              <div className="form-group mt-3">
                {submitting ? (
                  <button
                    type="submit"
                    className="btn btn-secondary py-1 text-white font-weight-bold mt-3 form-control"
                    disabled
                  >
                    Proceeding
                    <span
                      className="spinner-border spinner-border ml-2"
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        fontSize: '0.7rem',
                      }}
                      role="status"
                      aria-hidden="true"
                    />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="form-control btn btn-primary text-dark py-1 font-weight-bold mt-3"
                    onClick={handleSubmit}
                  >
                    Proceed
                  </button>
                )}
                <Link to={`/update-payment/card/proceed/pin?token=${token}`}>
                  <button
                    type="button"
                    className="form-control btn bg-light-secondary text-white py-1 font-weight-bold mt-3"
                  >
                    Back
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

Payment.propTypes = {
  verifyOtp: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  verifyOtpAction: PropTypes.func.isRequired,
  clearStatus: PropTypes.func.isRequired,
};

const mapStateProps = ({ verifyOtp }) => ({ verifyOtp });

export default connect(mapStateProps, {
  verifyOtpAction,
  clearStatus
})(Payment);
