/* eslint-disable no-useless-catch */
import axios, { config } from '..';

export default async (token) => {
  try {
    const response = await axios.get('/user/my-profile ', config(token));
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
