import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from '../components/Loader';
import Error from '../components/Error';
import fetchPaymentAction from '../redux/actions/payments/fetchPayment';
import PaymentDetails from '../components/PaymentDetails';

const Payment = ({
  getPayment,
  fetchPaymentAction: fetchPayment,
  location,
}) => {
  const history = useHistory();
  const [status, setStatus] = useState('initial');
  const [paymentData, setPaymentData] = useState('');
  const [token, setToken] = useState('');
  const [allowRefetch, setAllowRefetch] = useState(true);

  useEffect(() => {
    if (status === 'initial') {
      const urlToken = location.search.slice(
        location.search.indexOf('?token=') + 7,
        location.search.length
      );
      setToken(urlToken);
      fetchPayment(urlToken);
      setStatus('fetching');
    }
    if (getPayment.status === 'success') {
      setStatus('success');
      setPaymentData(getPayment.results);
    }

    if (getPayment.status === 'clear') {
      setStatus('fetching');
      setPaymentData({});
    }

    if (getPayment.status === 'error') {
      const { error } = getPayment;
      if (error.status === 500) setStatus('unknown_error');
      if (error.status === 404)
        return history.push(`/update-payment/select?token=${token}`);
    }

    return undefined;
  }, [getPayment]);

  const refetch = () => {
    if (allowRefetch) {
      fetchPayment(token);
      setStatus('fetching');
      setAllowRefetch(false);
    }
  };

  const DisplayData = ({ children }) => {
    let data;
    switch (status) {
      case 'success':
        data = <>{children}</>;
        break;
      case 'fetching':
        data = <Loader />;
        break;
      default:
        data = (
          <Error
            title="Error!"
            description="Something went wrong, Please try again in a while."
          />
        );
    }
    return data;
  };

  return (
    <>
      <div className="view-container">
        <div className="wrapper-content px-4 mt-2 bg-dark w-100 m-auto">
          <div className="form-content d-block bg-secondary px-5 pt-4">
            <h4 className="text-white text-center xlarge-text">Subscription</h4>
            <p className="text-white text-center" style={{ fontSize: 15 }}>
              Current Payment Details
            </p>
            <DisplayData>
              <PaymentDetails
                data={paymentData}
                token={token}
                refetch={refetch}
              />
              <div className="button-div mt-4">
                <Link
                  to={`/update-payment/select?token=${token}`}
                  className="form-control btn btn-primary text-dark py-1 font-weight-bold mt-3 mb-5"
                >
                  Update
                </Link>
              </div>
            </DisplayData>
          </div>
        </div>
      </div>
    </>
  );
};

Payment.propTypes = {
  getPayment: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchPaymentAction: PropTypes.func.isRequired,
};

const mapStateProps = ({ getPayment }) => ({ getPayment });

export default connect(mapStateProps, {
  fetchPaymentAction,
})(Payment);
