/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from './Icon';
import initiatePaymentAction from '../redux/actions/payments/initiatePayment';
import Loader from './Loader';

const PaymentInfo = ({
  data = {},
  initiatePaymentAction: initiatePayment,
  initiatePayment: initiate,
  token,
  refetch,
}) => {
  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    if (initiate.status === 'success') {
      setSubmitting(false);
      setErrors([]);
      if (initiate.message === 'Payment initiated') {
        setRedirecting(true);
        window.location.assign(initiate.data.meta.authorization.redirect);
      } else {
        refetch();
      }
    }

    if (initiate.status === 'error') {
      setSubmitting(false);
      return setErrors([initiate.error.message]);
    }

    return undefined;
  }, [initiate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors([]);
    setSubmitting(true);
    return initiatePayment(token);
  };

  let DisplayData;
  if (!data.paymentInfo) {
    DisplayData = () => (
      <div className="text-white text-center">
        <h4>No Payment Info Found</h4>
        <p style={{ fontSize: 15 }}>
          You need to add payment details to start using Ingoma
        </p>
      </div>
    );
  } else if (data.paymentInfo.method === 'Mobile Money') {
    DisplayData = () => (
      <>
        <div
          className="bg-dark text-center p-2"
          style={{ flex: 1, alignSelf: 'flex-start', borderRadius: 5 }}
        >
          <Icon name="phone" height={30} width={30} color="#ffffff" />
        </div>
        <div className="title-content ml-3" style={{ flex: 7 }}>
          <h4
            className="text-white large-text m-0 p-0"
            style={{ fontSize: 20 }}
          >
            {data.paymentInfo.method}
          </h4>
          <p className="text-white" style={{ fontSize: 15 }}>
            {`+250 78* *** *${data.paymentInfo.phone_number.slice(8, 10)}`}
          </p>
        </div>
      </>
    );
  } else {
    DisplayData = () => (
      <>
        <div
          className="bg-dark text-center p-2"
          style={{ flex: 1, alignSelf: 'flex-start', borderRadius: 5 }}
        >
          <Icon name="creditCard" height={30} width={30} color="#ffffff" />
        </div>
        <div className="title-content ml-3" style={{ flex: 7 }}>
          <h4
            className="text-white large-text m-0 p-0"
            style={{ fontSize: 20 }}
          >
            {data.paymentInfo.method}
          </h4>
          <p className="text-white" style={{ fontSize: 15 }}>
            {!data.paymentInfo.card ? '* Card Number *' : data.paymentInfo.card}
          </p>
        </div>
      </>
    );
  }

  const LoaderBtn = (
    <button
      type="submit"
      className="btn btn-secondary py-1 text-white font-weight-bold mt-2 form-control"
      disabled
    >
      Initiating
      <span
        className="spinner-border spinner-border ml-2"
        style={{
          width: '1.5rem',
          height: '1.5rem',
          fontSize: '0.7rem',
        }}
        role="status"
        aria-hidden="true"
      />
    </button>
  );

  return (
    <>
      {data.paymentInfo ? (
        <div className="text-center mt-4">
          {data.subscriptionStatus || data.subscriptionEndDate ? (
            data.subscriptionStatus === 'active' ? (
              <>
                <h4
                  className="text-white large-text m-0 p-0"
                  style={{ fontSize: 19 }}
                >
                  Subscription Ends on:
                </h4>
                <p className="text-white" style={{ fontSize: 15 }}>
                  {`${new Date(
                    data.subscriptionEndDate
                  ).getDate()} /  ${new Date(
                    data.subscriptionEndDate
                  ).getMonth() + 1} / ${new Date(
                    data.subscriptionEndDate
                  ).getFullYear()}`}
                </p>
              </>
            ) : (
              <>
                <h4
                  className="text-white large-text m-0 p-0"
                  style={{ fontSize: 19 }}
                >
                  Subscription Ended on:
                </h4>
                <p className="text-white" style={{ fontSize: 15 }}>
                  {`${new Date(
                    data.subscriptionEndDate
                  ).getDate()} /  ${new Date(
                    data.subscriptionEndDate
                  ).getMonth() + 1} / ${new Date(
                    data.subscriptionEndDate
                  ).getFullYear()}`}
                </p>
                {redirecting ? (
                  <>
                    <Loader />
                    <p className="mt-0 text-white text-center">Finishing Up</p>
                  </>
                ) : submitting ? (
                  LoaderBtn
                ) : (
                  <button
                    type="button"
                    className="form-control btn btn-primary text-dark py-1 font-weight-bold mt-3"
                    onClick={handleSubmit}
                  >
                    Initiate
                  </button>
                )}
              </>
            )
          ) : (
            <>
              <h4
                className="text-white large-text mt-0 mb-2 p-0"
                style={{ fontSize: 19 }}
              >
                No Subscription Found!
              </h4>
              {redirecting ? (
                <>
                  <Loader />
                  <p className="mt-0 text-white text-center">Finishing Up</p>
                </>
              ) : submitting ? (
                LoaderBtn
              ) : (
                <button
                  type="button"
                  className="form-control btn btn-primary text-dark py-1 font-weight-bold mt-3"
                  onClick={handleSubmit}
                >
                  Initiate
                </button>
              )}

              {errors.map((error, index) => (
                <div
                  className="alert alert-danger alert-dismissible my-2"
                  role="alert"
                  key={index}
                >
                  {error}
                  <button
                    type="button"
                    className="close"
                    onClick={() => setErrors([])}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              ))}
            </>
          )}
        </div>
      ) : null}
      <div className="payment-icon-content mt-5">
        <DisplayData />
      </div>
    </>
  );
};

PaymentInfo.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  initiatePayment: PropTypes.objectOf(PropTypes.any).isRequired,
  initiatePaymentAction: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

const mapStateProps = ({ initiatePayment }) => ({ initiatePayment });

export default connect(mapStateProps, {
  initiatePaymentAction,
})(PaymentInfo);
