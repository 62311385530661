import * as yup from 'yup';

const validators = {
  password: yup
    .string()
    .min(6, 'The password must be atleast 6 characters long'),
  oldPassword: yup
    .string()
    .min(6, 'The password must be atleast 6 characters long'),
  newPassword: yup
    .string()
    .min(6, 'The password must be atleast 6 characters long'),
  momoNumber: yup
    .string()
    .matches(
      /^[0][7][8][0-9]{7}$/,
      'Tel must be 10 digits and start with "078..."'
    ),
  cardname: yup
    .string()
    .matches(/\s/, 'Please enter the name on the card')
    .required('A Card Name is required'),
  card_number: yup.string().min(16, 'Credit Card number must be 16 digits'),
  expiry_month: yup
    .string()
    .length(2, 'The Expiry Month is required and must be 2 digits'),
  phone_number: yup.string().length(10, 'Tel Must be atleast 10 digits!'),
  expiry_year: yup
    .string()
    .length(4, 'The Expiry year is required and must be 4 digits'),
  cvv: yup.string().length(3, 'The CVV is required and must be 3 digits'),
};

export default (value) => {
  const selected = {};
  Object.keys(value).forEach((key) => {
    selected[key] = validators[key];
  });
  return yup
    .object()
    .shape(selected)
    .validate(value, { abortEarly: false })
    .catch((err) => err.errors);
};
