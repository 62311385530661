import React from 'react';
import {
  BrowserRouter as Router, Switch, Route, Redirect
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '../redux/store';
import EditProfile from './EditProfile';
import EditPassword from './EditPassword';
import PaymentInfo from './PaymentInfo';
import ChooseSubsrciption from './ChooseSubsrciption';
import UpdateMomo from './UpdateMomo';
import UpdateCard from './UpdateCard';
import ProceedAvs from './ProceedAvs';
import ProceedPin from './ProceedPin';
import NewPassword from './NewPassword';
import VerifyAccount from './VerifyAccount';
import VerifyOtp from './VerifyOtp';

export default function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/update-profile" component={EditProfile} />
          <Route exact path="/update-password" component={EditPassword} />
          <Route exact path="/new-password" component={NewPassword} />
          <Route exact path="/subscription/details" component={PaymentInfo} />
          <Route exact path="/verify" component={VerifyAccount} />
          <Route
            exact
            path="/update-payment/select"
            component={ChooseSubsrciption}
          />
          <Route exact path="/update-payment/card" component={UpdateCard} />
          <Route
            exact
            path="/update-payment/mobile-money"
            component={UpdateMomo}
          />
          <Route
            exact
            path="/update-payment/card/proceed/avs"
            component={ProceedAvs}
          />
          <Route
            exact
            path="/update-payment/card/proceed/pin"
            component={ProceedPin}
          />
          <Route
            exact
            path="/update-payment/card/proceed/verify-otp"
            component={VerifyOtp}
          />
          <Route
            exact
            path="/subscription/details/:id"
            render={({ location, history }) => {
              const arr = location.pathname.split('/');
              const token = arr[arr.length - 1];
              history.push(`/subscription/details?token=${token}`);
            }}
          />
        </Switch>
      </Router>
    </Provider>
  );
}
